<template>
  <section id="vacation-tracking">
    <b-row>
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-success">
                {{ vacationRemainingDays }} days
              </h2>
              <span>Remaining vacation</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-warning">
                {{ sickdayRemainingDays }} days
              </h2>
              <span>Remaining sickday</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{ 'show': isCalendarOverlaySidebarActive }">
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <div class="body-content-overlay" :class="{ 'show': isCalendarOverlaySidebarActive }"
          @click="isCalendarOverlaySidebarActive = false" />
        <calendar-event-handler v-model="isEventHandlerSidebarActive" :event="event" :clear-event-data="clearEventData"
          @remove-event="removeEvent" @add-event="addEvent" @update-event="updateEvent" />
      </div>
    </div>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import axios from 'axios'
import calendarStoreModule from './calendar/calendarStoreModule'
import CalendarSidebar from './calendar/calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar/calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './calendar/useCalendar'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  data() {
    return {
      vacationRemainingDays: '',
      sickdayRemainingDays: '',
    }
  },
  created() {
    this.getRemainingDays()
  },
  methods: {
    getRemainingDays() {
      axios
        .get(`${process.env.VUE_APP_ROOT_API}/remaining-days/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          const { results } = response.data
          this.vacationRemainingDays = results[0].vacation_days_left + results[0].vacation_remainder
          this.sickdayRemainingDays = results[0].sick_days_days_left
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
