import axios from 'axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Vacation',
      },
      {
        color: 'warning',
        label: 'Sickday',
      },
    ],
    selectedCalendars: ['Vacation', 'Sickday'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, start, end }) {
      return new Promise((resolve, reject) => {
        const requestsList = []
        const queryParams = {}

        const fromDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
        const toDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
        queryParams.from_date = fromDate
        queryParams.to_date = toDate

        if (calendars.includes('Vacation')) {
          const requestVacations = axios
            .get(`${process.env.VUE_APP_ROOT_API}/vacations/`, {
              headers: {
                Authorization: `JWT ${this.state.jwt}`,
                'Content-Type': 'application/json',
              },
              params: queryParams,
            })
          requestsList.push(requestVacations)
        }
        if (calendars.includes('Sickday')) {
          const requestSickdays = axios
            .get(`${process.env.VUE_APP_ROOT_API}/sickdays/`, {
              headers: {
                Authorization: `JWT ${this.state.jwt}`,
                'Content-Type': 'application/json',
              },
              params: queryParams,
            })
          requestsList.push(requestSickdays)
        }

        axios
          .all(requestsList)
          .then(response => {
            let responseData = []
            for (var i = 0; i < response.length; i++) {
              responseData = responseData.concat(response[i].data.results)
            }
            resolve(responseData)
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' })
            } else {
              reject(error)
            }
          })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        let requestUrl = ''
        const queryParams = {}

        if (event.extendedProps.calendar === 'Vacation') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/vacations/`
        } else if (event.extendedProps.calendar === 'Sickday') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/sickdays/`
        }

        queryParams.user = localStorage.getItem('useridLocal')
        queryParams.start = event.start
        queryParams.end = event.end
        queryParams.description = event.extendedProps.description
        queryParams.representative = event.extendedProps.representative_user.value

        axios
          .post(requestUrl, queryParams, {
            headers: {
              Authorization: `JWT ${this.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' })
            } else {
              reject(error)
            }
          })
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        let requestUrl = ''
        const queryParams = {}

        if (event.extendedProps.calendar === 'Vacation') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/vacations/${event.id}/`
        } else if (event.extendedProps.calendar === 'Sickday') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/sickdays/${event.id}/`
        }

        if (typeof event.start === 'string') {
          queryParams.start = event.start
        } else {
          const date = event.start.getDate()
          const month = event.start.getMonth() + 1
          const year = event.start.getFullYear()
          queryParams.start = `${year}-${month}-${date}`
        }

        if (typeof event.end === 'string') {
          queryParams.end = event.end
        } else {
          const date = event.end.getDate()
          const month = event.end.getMonth() + 1
          const year = event.end.getFullYear()
          queryParams.end = `${year}-${month}-${date}`
        }

        queryParams.user = localStorage.getItem('useridLocal')
        queryParams.description = event.extendedProps.description
        if (typeof event.extendedProps.representative_user === 'string') {
          queryParams.representative = event.extendedProps.representative
        } else {
          queryParams.representative = event.extendedProps.representative_user.value
        }

        axios
          .patch(requestUrl, queryParams, {
            headers: {
              Authorization: `JWT ${this.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' })
            } else {
              reject(error)
            }
          })
      })
    },
    removeEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        let requestUrl = ''

        if (event.extendedProps.calendar === 'Vacation') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/vacations/${event.id}/`
        } else if (event.extendedProps.calendar === 'Sickday') {
          requestUrl = `${process.env.VUE_APP_ROOT_API}/sickdays/${event.id}/`
        }

        if (event.title.includes('(Pending)')) {
          axios
            .delete(requestUrl, {
              headers: {
                Authorization: `JWT ${this.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        }
      })
    },
  },
}
